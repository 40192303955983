.page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.page__title {
  font-size: 32px;
  font-weight: bold;
}

.page__content {
  padding: 0 15px;
}
