.list-group {
  margin-top: 20px;
  max-width: max-content;
}

.list-group-item {
  padding-right: 80px;
  position: relative;
}

.list-group-item.list-group-item__user {
  padding-right: 160px;
}

.list-group-item.list-group-item__enum {
  padding-right: 120px;
}

.list-group-item.list-group-item__template {
  padding-right: 120px;
}

.list-group-item:hover {
  background-color: #e8fff5;
}

.disable-item-btn {
  position: absolute;
  right: 0;
  top: calc(50% - 20px);
}

.item-btn {
  display: flex;
  align-items: center;
  position: absolute;
  right: 40px;
  top: calc(50% - 20px);
  height: 32px;
}

.item-btn_disable {
  right: 0;
}

.item-btn_reset-pwd {
  right: 80px;
}

.add-new-btn {
  margin-top: 20px;
}

.create-text-wrapper {
  margin-bottom: 15px;
}

.create-text-wrapper label {
  margin-bottom: 5px;
  margin-right: 10px;
}

.popup__template-content {
  width: 50%;
}

.textarea__template.form-control {
  min-height: 200px;
}

.form-wrapper {
  margin-top: 20px;
}
