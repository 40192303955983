.list__header {
  text-align: right;
}

.list__footer {
  text-align: right;
}

.list__item {
  margin: 10px 0;
  display: flex;
}

.list__item-content {
  flex: 1;
}

.list__item-tools {
  margin-left: 10px;
}
