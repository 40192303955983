/* src/custom.css */
.custom-tab-container {
    border: none;
    padding: 0.1px;
    border-radius: 5px;
    background-color: #f8f9fa; /* light grey background */
}

.custom-tab-container .nav-link {
    color: #343a40; /* soft black color for non-active items */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    margin-right: 5px; /* space between tabs */
    transition: background-color 0.3s ease; /* transition for smooth color change */
}

.custom-tab-container .nav-link:hover {
    background-color: #e2e6ea; /* light blueish color on hover */
}

.custom-tab-container .nav-link.active {
    color: #495057; /* darker text color for active item */
    background-color: #f8f9fa; /* same as container background color */
    font-weight: bold; /* make text bold */
    border-bottom: 2px solid #007bff; /* blue bottom border */
}

.custom-tab-container .tab-content {
    padding: 5px;
    background-color: #f8f9fa; /* same as container background color */
    border-radius: 5px;
}
