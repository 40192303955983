.fc-item-bottom {
  margin: 5px 0;
}

.fc-to-right {
  text-align: right;
}

.fc-button {
  margin: 0;
}

.fc-file-list {
  margin: 10px 0;
}

.fc-remove-file {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  position: relative;
  top: -1px;
}


.drop-base-styled {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.drop-base-styled__focused {
  border-color: #2196f3;
}

.drop-base-styled__accepted {
  border-color: #00e676;
}

.drop-base-styled__rejected {
  border-color: #ff1744;
}

.fc-card-header {
  white-space: pre;
}

.fc-card {
  margin-bottom: 1rem !important;
}

.fc-match-meta {
  margin-bottom: 20px;
}

.fc-meta-item {
  margin-bottom: 0 !important;
}

.diff-wrapper {
  background-color: #f7f7f7;
  font-size: 14px;
}

.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

.slider-label {
    margin-bottom: 10px;
    text-align: center;
    position: relative; /* Ensure label is positioned relative */
}

.info-icon {
    display: inline-block;
    color: #fff; /* Color for the question mark */
    background-color: #0095D9; /* Background color for the circle */
    border-radius: 50%; /* Make the background circle */
    width: 20px; /* Width of the circle */
    height: 20px; /* Height of the circle */
    line-height: 20px; /* Align the "?" vertically center */
    text-align: center; /* Align the "?" horizontally center */
    margin-right: 5px; /* Space between icon and text */
    cursor: default; /* Add a pointer on hover */
    position: relative; /* Position icon relative, so tooltip positions according to it */
}

.tooltip-text {
    visibility: hidden;
    background-color: #0095D9;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s; /* Tooltip fades in/out */
    margin-left: -60px;
    width: 150px; /* Adjust width to fit two lines of text */
    line-height: 1.5em; /* Adjust line height */
}

.info-icon:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    /* Add any other styles as per your design requirements */
}
