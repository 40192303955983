/* Import default styles from rc-slider */
@import "~rc-slider/assets/index.css";

.rc-slider {
    width: 80%; /* Adjust the width as per your requirement */
    margin: auto;
}

.rc-slider-track {
    background-color: #2196f3 !important; /* Blue track */
}

.rc-slider-handle {
    border: #2196f3 solid 2px !important; /* Blue handle border */
    height: 20px !important; /* Adjust the size */
    width: 20px !important; /* Adjust the size */
    margin-top: -9px !important; /* Center align handle with track */
    background-color: #2196f3 !important; /* Blue handle */
    opacity: 1 !important; /* Make handle fully opaque */
}

.rc-slider-rail {
    height: 2px; /* Adjust the thickness of the rail */
}

.rc-slider-tooltip-inner {
    background-color: #2196f3 !important; /* Blue tooltip background */
}

.rc-slider-tooltip-arrow {
    border-top-color: #2196f3 !important; /* Blue tooltip arrow border */
}
