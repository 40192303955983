.projectIcon {
    max-width: 300px;
    border: 1px solid;
    padding: 20px;
    border-radius: 10px;
}

.iconDeleteBtn {
    margin-left: 20px;
}

/* custom file input */
.iconInput {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

    & + label {
        cursor: pointer;
        display: flex;
        height: 32px;
        width: max-content;
        border: 1px solid;
        border-radius: 0.35rem;
        
        &:hover {
            border-color: #424649;
            color: #424649;

            & > .labelIcon {
                background-color: #424649;
            }
        }
    }

    &:focus .labelIcon {
        background-color: #424649;
    }
}

.iconInputWrapper {
    position: relative;
}

.labelIcon {
    font-size: 1.25em;
    background-color: black;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    transition: all 0.2s;
}

.fileChoose {
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    position: relative;
    left: -3px;
    transition: all 0.2s;
}

.error {
    color: red;
}

.hintTooltip {
    position: absolute;
    top: 2px;
    left: 160px;
    z-index: 10;
}