.fixed-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  border-radius: 10px;
  background: #ffffff;
  padding: 5px;
  padding-left: 12px;
  padding-right: 16px;
  box-shadow: 4px -5px 10px #dee2e5, -4px 5px 10px #dee2e5;
  z-index: 999;

  &__no-actions {
    padding: 12px;
  }
}

.checkbox-col {
  flex: 0 0 125px;

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}