.header-logo {
  display: inline-flex;
  align-items: center;
}

.header-logo img {
  margin-right: 5px;
  /* width: 19px; */
  height: 19px;
}
