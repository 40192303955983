.pagination-row {
  margin: 10px 0;
  padding-left: 120px;
  position: relative;
  min-height: 38px;
}

.pagination-row .options-select {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.pagination {
  opacity: 0.99; /* temporary fix */
}

.table-container {
  overflow: auto;
}

.table-container .audit-id .loader {
  border: 2px solid green;
  border-left-color: transparent;
  margin-left: 6px;
}

.sort-icon {
  margin-left: 5px;
  position: relative;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.sort-icon__up {
  bottom: 14px;
  border-bottom: 10px solid #9d9d9d;
}

.sort-icon__down {
  top: 14px;
  border-top: 10px solid #9d9d9d;
}

.sort-icon__up.sort-icon__active {
  border-bottom-color: #198754;
}

.sort-icon__down.sort-icon__active {
  border-top-color: #198754;
}

.close-popup-btn {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  float: right;
}

.right-position-btn {
  float: right;
}
/* form */

.checkbox {
  left: -9999px;
  width: 0;
  height: 0;
}

.checkbox + label {
  position: relative;
  padding-left: 32px;
}

.checkbox + label:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: all 0.2s;
}

.checkbox + label:after {
  content: "\2713";
  display: block;
  position: absolute;
  left: 3px;
  top: -5px;
  font-size: 20px;
  transition: all 0.2s;
}

.checkbox:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.checkbox:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.checkbox:disabled + label:before {
  background-color: #a8abb1;
}
