.active-auditor {
  padding-right: 30px;
  position: relative;
}

.active-auditor button {
  position: absolute;
  right: 0;
  top: calc(50% - 15px);
}
