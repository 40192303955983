.button {
  margin: 5px;
}

.btn-circle {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  line-height: 1.4286;
}

.btn-circle svg {
  width: 16px;
  height: 16px;
}
