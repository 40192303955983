.text-editor-wrapper {
  border: 1px solid #ced4da;
  padding: 5px;
  border-radius: .25rem;
  background-color: #ffffff;

  &_error {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
  }
}

.text-editor-wrapper .ce-block__content,
.text-editor-wrapper .ce-toolbar__content {
  width: 100%;
  max-width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}

.text-editor-wrapper .ce-toolbar__actions {
  right: 0;
}

.text-editor-wrapper .ce-toolbar__plus {
  left: 0;
}

.text-editor-wrapper .codex-editor__redactor {
  padding-bottom: 50px !important;
}

.focus-editor .codex-editor {
 z-index: 2;
}
