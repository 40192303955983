:root {
    --code-highlight-comment-color: #008000;
    --code-highlight-prolog-color: #008000;
    --code-highlight-doctype-color: #008000;
    --code-highlight-cdata-color: #008000;
    --code-highlight-tag-color: #c92c2c;
    --code-highlight-entity-color: #a67f59;
    --code-highlight-atrule-color: #00f;
    --code-highlight-url-color: #a67f59;
    --code-highlight-selector-color: #2f9c0a;
    --code-highlight-string-color: #2f9c0a;
    --code-highlight-property-color: #c92c2c;
    --code-highlight-important-color: #e90;
    --code-highlight-punctuation-color: #5f6364;
    --code-highlight-number-color: #c92c2c;
    --code-highlight-function-color: #2f9c0a;
    --code-highlight-class-name-color: #00f;
    --code-highlight-keyword-color: #00f;
    --code-highlight-boolean-color: #c92c2c;
    --code-highlight-operator-color: #a67f59;
    --code-highlight-char-color: #2f9c0a;
    --code-highlight-regex-color: #e90;
    --code-highlight-variable-color: #a67f59;
    --code-highlight-constant-color: #c92c2c;
    --code-highlight-symbol-color: #c92c2c;
    --code-highlight-function-name-color: #c92c2c;
    --code-highlight-builtin-color: #2f9c0a;
    --code-highlight-attr-name-color: #2f9c0a;
    --code-highlight-attr-value-color: #00f;
    --code-highlight-inserted-color: #2f9c0a;
    --code-highlight-deleted-color: #c92c2c;
    --code-highlight-namespace-color: #c92c2c;
}
