.help {
  &__tooltip-title {
    font-size: 24px;
    line-height: 30px;
    border-bottom: 1px dotted;
  }

  &__tooltip-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    margin-bottom: 10px;
  }

  &__hotkeys-table {
    th {
      background-color: #4e4e4e;
    }
  }
}
