.checkbox-center {
  text-align: center;
}

.checkbox-label {
  height: 15px;
}

.c-date-picker {
  border-radius: 5px;
  border-color: #ced4da;
  width: 100%;
}

.c-date-picker .react-date-picker__wrapper {
  background: white;
}

.c-date-picker .react-calendar {
  position: fixed;
}
