.react-date-picker {
  width: 100%;

  &__wrapper {
    border-radius: 5px;
    border-color: #ced4da;
    width: 100%;
    padding: 5px;
  }

  &.error &__wrapper {
    border-color: #dc3545;
  }

  &__calendar {
    z-index: 100000;
  }
}
