.popup-overlay {
  overflow: scroll;
}

.c-popup-content {
  background: #f1f1f1;
  padding: 30px 40px;
  border-radius: 10px;
  border: 1px solid lightgray;
  animation: 0.5s ease-in-out popup_anim;
}

.c-popup-half-content {
  width: 50%;
}

.c-popup-full-content {
  width: 90%;
}

.edit-issue-content {
  max-height: 700px;
  overflow: auto;
}

@media (max-width: 1024px) {

  .c-popup-half-content {
    width: 90%;
  }
}

.c-popup-overlay {
  background: rgb(0 0 0 / 50%);
  animation: 0.2s ease-in-out popup_overlay_anim;
}

@keyframes popup_overlay_anim {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes popup_anim {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
