table.app-table {
  border-spacing: 0;
  border-collapse: unset;
  min-width: 100%;
}

table.app-table th,
table.app-table td {
  font-size: 14px;
  margin: 0;
  padding: 0.5rem;
  background: white;
  border: 1px solid #cccccc;
  border-top: 0;
  border-left: 0;
}

table.app-table tr th:first-child,
table.app-table tr td:first-child {
  border-left: 1px solid #cccccc;
}

table.app-table tr th:last-child,
table.app-table tr td:last-child {
  border-left: 1px solid #cccccc;
}

table.app-table .fixed-cell {
  position: sticky;
  z-index: 10;
}

table.app-table .fixed-cell_control {
  z-index: 11;
}

table.app-table .fixed-cell_control {
  z-index: 11;
}

table.app-table .fixed-cell:after {
  position: absolute;
  top: 0;
  bottom: -1px;
  width: 20px;
  transition: box-shadow .3s;
  content: "";
  pointer-events: none;
}

table.app-table .fixed-first-right-cell:after {
  left: -1px;
  transform: translateX(-100%);
  box-shadow: inset -10px 0 8px -8px green;
}

table.app-table .fixed-last-left-cell:after {
  right: -1px;
  transform: translateX(100%);
  box-shadow: inset 10px 0 8px -8px green;
}

table.app-table tr th:nth-last-child(2),
table.app-table tr td:nth-last-child(2) {
  border-right: 0;
}

table.app-table thead th {
  background-color: #4e4e4e;
  color: white;
}

table.app-table thead tr:not(:first-child) td {
  background-color: #7b7b7b;
  color: #212529;
}

table.app-table tbody tr:nth-child(even) td {
  background-color: #f5f5f5;
}

table.app-table tbody tr:hover td {
  background-color: #e8fff5;
}

.scroll-max-right .fixed-first-right-cell:after {
  box-shadow: unset !important;
}

.scroll-max-left .fixed-last-left-cell:after {
  box-shadow: unset !important;
}

.sort-btn-wrap {
  white-space: nowrap;
}

.sort-btn {
  font: inherit;
  background: transparent;
  display: inline;
  color: inherit;
  border: none;
  outline: none;
}

.sort-btn:active,
.sort-btn:focus {
  border: none;
  outline: none;
}
